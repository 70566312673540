import React from 'react';
import styled from 'styled-components'
import { Link } from 'gatsby'

import Heading from 'components/global/Heading'
import Button from 'components/global/Button';
import Container from 'components/global/Container'

import BgPattern from 'assets/imgs/home/BgPattern2';
import PluridisciplinaireIcon from 'assets/icons/aboutSection/PluridisciplinaireIcon'
import CerticationIcon from 'assets/icons/aboutSection/CerticationIcon'
import PartnersIcon from 'assets/icons/aboutSection/PartnersIcon'
import ProceduresIcon from 'assets/icons/aboutSection/ProceduresIcon'
import IndependanceIcon from 'assets/icons/aboutSection/IndependanceIcon'
import RespectIcon from 'assets/icons/aboutSection/RespectIcon'


const Index = () => {
  return (
    <StyledBgPatternContainer>
      <Container>
        <Heading section>Notre ADN</Heading>
        <StyledPointsContainer>
          <StyledPoint>
            <StyledIconContainer>
              <PluridisciplinaireIcon />
            </StyledIconContainer>
            <p>Une forte expertise technique pluridisciplinaire</p>
          </StyledPoint>
          <StyledPoint>
            <StyledIconContainer>
              <CerticationIcon />
            </StyledIconContainer>
            <p>Méthode de gestion<br />de projets certifiée Prince&nbsp;2</p>
          </StyledPoint>
          <StyledPoint>
            <StyledIconContainer>
              <PartnersIcon />
            </StyledIconContainer>
            <p>Un réseau<br />de partenaires<br />experts</p>
          </StyledPoint>
          <StyledPoint>
            <StyledIconContainer>
              <ProceduresIcon />
            </StyledIconContainer>
            <p>Maitrise des procédures de la commande publique</p>
          </StyledPoint>
          <StyledPoint>
            <StyledIconContainer>
              <IndependanceIcon />
            </StyledIconContainer>
            <p>Indépendance vis-à-vis des constructeurs et intégrateurs</p>
          </StyledPoint>
          <StyledPoint>
            <StyledIconContainer>
              <RespectIcon />
            </StyledIconContainer>
            <p>Un respect des conformités normatives et règlementaires</p>
          </StyledPoint>
        </StyledPointsContainer>
        <Link to="/a-propos-de-nous">
          <Button secondary center>À propos de nous</Button>
        </Link>
      </Container>
      <StyledBgPattern />
    </StyledBgPatternContainer>
  );
}

const StyledPointsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`
const StyledPoint = styled.div`
  display: flex;
  flex: 0 0 288px;
  align-items: center;
  margin: 0 40px 100px;
`
const StyledIconContainer = styled.div`
  background-color: ${props => props.theme.colors.brand};
  flex: 0 0 100px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`
const StyledBgPatternContainer = styled.div`
  position: relative;
`
const StyledBgPattern = styled(BgPattern)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`

export default Index;
