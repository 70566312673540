import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'gatsby'

import Container from 'components/global/Container'
import Button from 'components/global/Button';
import Heading from 'components/global/Heading'
import Card from 'components/global/Card'

const Index = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(
        limit: 3,
        sort: { fields: [date], order: DESC },
        filter: {type: {eq: "Actu"}}
        ) {
        nodes {
          id
          title
          slug
          date(formatString: "Do MMMM YYYY", locale: "fr")
          tags
          heroImage {
            gatsbyImageData(
              width: 660
              height: 450
            )
          }
          accroche {
            accroche
          }
          type
        }
      }
    }
  `)

  const posts = data.allContentfulArticle.nodes
  const postsRender = posts.map(post => {
    return (
      <Card key={post.id} post={post} />
    )
  })

  return (
    <Container>
      <Heading section>Nos actus Récentes</Heading>
      <StyledCardsContainer>
        {postsRender}
      </StyledCardsContainer>
      <Link to="/actus">
        <StyledButton secondary>voir toutes nos actus</StyledButton>
      </Link>
    </Container>
  );
}

const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    &:nth-child(2) {
      display: none;
      ${breakpoint('md')`
        display: flex;
      `}
    }
    &:nth-child(3) {
      display: none;
      ${breakpoint('xl')`
        display: flex;
      `}
    }
  }
`
const StyledButton = styled(Button)`
  margin: 50px auto 120px;
`

export default Index;
