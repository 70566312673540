import React from 'react'

export default function RespectIcon() {
  return (
    <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.0564 24.23L34.6249 24.1037C34.4204 21.3154 33.2815 18.6965 31.414 16.72C29.5466 14.7435 27.0735 13.5396 24.4416 13.3259L24.3223 14.9877C26.5793 15.1713 28.7 16.2036 30.3016 17.8984C31.9033 19.5932 32.8803 21.8389 33.0564 24.23Z" fill="white" />
      <path d="M31.42 33.2747C33.2862 31.3001 34.4232 28.6827 34.6251 25.8966L33.0566 25.7705C32.8805 28.1616 31.9034 30.4072 30.3017 32.102C28.7 33.7968 26.5793 34.8291 24.3223 35.0125L24.4418 36.6743C27.0763 36.4599 29.5515 35.2541 31.42 33.2747Z" fill="white" />
      <path d="M22.7501 36.6758L22.8675 35.0138C20.6083 34.8332 18.4846 33.8021 16.8804 32.1069C15.2763 30.4117 14.2976 28.1643 14.1213 25.7711L12.5527 25.8972C12.7575 28.688 13.8983 31.309 15.7687 33.286C17.6391 35.263 20.1156 36.4655 22.7501 36.6758Z" fill="white" />
      <path d="M22.8682 14.987L22.7502 13.325C20.1155 13.5352 17.6389 14.7378 15.7684 16.715C13.898 18.6922 12.7573 21.3134 12.5527 24.1044L14.1213 24.2304C14.2975 21.8371 15.2762 19.5895 16.8805 17.8943C18.4847 16.199 20.6086 15.1679 22.868 14.9875L22.8682 14.987Z" fill="white" />
      <path d="M23.6018 33.2774C23.762 33.2774 23.9184 33.2255 24.05 33.1288L25.5035 32.061C27.9523 30.2618 29.5949 27.639 30.1279 24.6805L30.6576 21.8276C30.6908 21.6487 30.6677 21.4631 30.5919 21.2994C30.516 21.1357 30.3916 21.0029 30.2376 20.9213L23.9481 17.5879C23.8389 17.53 23.7184 17.4999 23.5962 17.4999C23.4741 17.4999 23.3536 17.53 23.2444 17.5879L20.8847 18.838L21.5885 20.3286L23.5961 19.2652L28.9984 22.1284L28.5835 24.3634C28.1274 26.8947 26.7155 29.1425 24.6071 30.6914L23.6043 31.4278L22.5279 30.6266C20.4486 29.0789 19.0547 26.8452 18.6021 24.3311L18.1934 22.1284L20.0149 21.163L19.3111 19.6724L16.9543 20.9214C16.8003 21.003 16.6759 21.1358 16.6 21.2995C16.5242 21.4632 16.5011 21.6488 16.5343 21.8277L17.0577 24.6482C17.5864 27.5851 19.208 30.1927 21.6236 31.9906L23.1493 33.126C23.2818 33.2248 23.4399 33.2776 23.6018 33.2774Z" fill="white" />
      <path d="M23.5961 27.5003C23.6994 27.5005 23.8017 27.479 23.8972 27.4371C23.9926 27.3952 24.0793 27.3337 24.1523 27.2563L27.2984 23.9228L26.1861 22.7443L23.5961 25.4885L22.5792 24.411L21.4668 25.5895L23.0399 27.2563C23.1129 27.3337 23.1995 27.3952 23.295 27.4371C23.3904 27.479 23.4928 27.5005 23.5961 27.5003Z" fill="white" />
      <path d="M7.88471 25.8335C8.02748 29.0048 9.02872 32.0654 10.7689 34.6501L12.0508 33.684C10.5004 31.3813 9.60154 28.6581 9.45958 25.8335H11.0112V24.1668H9.45958C9.60143 21.3423 10.5001 18.6191 12.0504 16.3163L10.7685 15.3502C9.02847 17.9349 8.02736 20.9956 7.88471 24.1668H6.19246C6.00102 23.3812 5.54574 22.6966 4.91196 22.2412C4.27818 21.7859 3.50941 21.5911 2.74975 21.6933C1.99009 21.7955 1.2917 22.1878 0.785493 22.7966C0.279281 23.4053 0 24.1888 0 25.0002C0 25.8115 0.279281 26.595 0.785493 27.2037C1.2917 27.8125 1.99009 28.2048 2.74975 28.307C3.50941 28.4092 4.27818 28.2144 4.91196 27.7591C5.54574 27.3037 6.00102 26.6191 6.19246 25.8335H7.88471ZM3.14578 26.6669C2.83465 26.6669 2.53051 26.5691 2.27181 26.386C2.01312 26.2028 1.81149 25.9425 1.69243 25.638C1.57337 25.3334 1.54221 24.9983 1.60291 24.675C1.66361 24.3517 1.81343 24.0547 2.03343 23.8216C2.25343 23.5885 2.53373 23.4298 2.83888 23.3655C3.14403 23.3012 3.46033 23.3342 3.74777 23.4603C4.03522 23.5865 4.2809 23.8001 4.45375 24.0742C4.62661 24.3483 4.71887 24.6705 4.71887 25.0002C4.71837 25.442 4.55248 25.8657 4.25757 26.1781C3.96267 26.4906 3.56283 26.6663 3.14578 26.6669Z" fill="white" />
      <path d="M44.0456 21.6667C43.3483 21.6677 42.6709 21.9136 42.1196 22.366C41.5683 22.8184 41.1742 23.4517 40.9989 24.1668H39.3067C39.1639 20.9956 38.1627 17.9349 36.4225 15.3502L35.1406 16.3163C36.691 18.619 37.5898 21.3422 37.7318 24.1668H36.1801V25.8335H37.7318C37.59 28.658 36.6913 31.3812 35.141 33.684L36.4229 34.6501C38.1629 32.0654 39.164 29.0047 39.3067 25.8335H40.9985C41.1456 26.4371 41.4495 26.985 41.8758 27.4148C42.302 27.8446 42.8334 28.1391 43.4097 28.265C43.986 28.3908 44.5841 28.3429 45.136 28.1267C45.6878 27.9105 46.1714 27.5346 46.5318 27.0418C46.8922 26.549 47.1149 25.959 47.1747 25.3388C47.2345 24.7186 47.1289 24.093 46.87 23.5332C46.611 22.9734 46.2091 22.5017 45.7098 22.1718C45.2106 21.8419 44.634 21.6669 44.0456 21.6667ZM44.0456 26.6669C43.7345 26.6669 43.4303 26.5691 43.1716 26.386C42.9129 26.2028 42.7113 25.9425 42.5923 25.638C42.4732 25.3334 42.442 24.9983 42.5027 24.675C42.5634 24.3517 42.7133 24.0547 42.9333 23.8216C43.1533 23.5885 43.4336 23.4298 43.7387 23.3655C44.0439 23.3012 44.3602 23.3342 44.6476 23.4603C44.935 23.5865 45.1807 23.8001 45.3536 24.0742C45.5264 24.3483 45.6187 24.6705 45.6187 25.0002C45.6182 25.442 45.4523 25.8657 45.1574 26.1781C44.8625 26.4906 44.4627 26.6663 44.0456 26.6669Z" fill="white" />
      <path d="M9.13582 13.0109C9.69775 13.0115 10.2496 12.8527 10.734 12.5509L14.1414 16.1611L15.2538 14.9826L11.8464 11.3724C12.1995 10.7355 12.3434 9.9925 12.2555 9.25973C12.1677 8.52695 11.853 7.84572 11.3607 7.32267C10.995 6.93467 10.5446 6.64824 10.0494 6.48878C9.55419 6.32932 9.02953 6.30176 8.52189 6.40852C8.01425 6.51529 7.53931 6.75309 7.13916 7.10085C6.73901 7.44861 6.42601 7.89558 6.2279 8.40217C6.02979 8.90875 5.9527 9.45929 6.00344 10.005C6.05419 10.5507 6.23121 11.0747 6.51882 11.5306C6.80643 11.9865 7.19574 12.3601 7.65225 12.6185C8.10876 12.8768 8.61838 13.0118 9.13592 13.0116L9.13582 13.0109ZM8.02355 8.50041C8.31883 8.18835 8.71887 8.01311 9.13592 8.01311C9.55298 8.01311 9.95301 8.18835 10.2483 8.50041C10.4683 8.7335 10.6181 9.03048 10.6788 9.35379C10.7395 9.6771 10.7084 10.0122 10.5893 10.3168C10.4703 10.6213 10.2686 10.8816 10.0099 11.0648C9.75125 11.2479 9.4471 11.3457 9.13597 11.3457C8.82484 11.3457 8.5207 11.2479 8.262 11.0648C8.00331 10.8816 7.80168 10.6213 7.68262 10.3168C7.56356 10.0122 7.53241 9.6771 7.59311 9.35379C7.65381 9.03048 7.80364 8.7335 8.02365 8.50041H8.02355Z" fill="white" />
      <path d="M40.282 37.9642C39.7883 37.4425 39.1452 37.1091 38.4535 37.016C37.7618 36.9229 37.0604 37.0753 36.4592 37.4496L33.0518 33.8394L31.9395 35.0179L35.3469 38.6281C35.0481 39.1652 34.8974 39.7803 34.912 40.4035C34.9266 41.0267 35.1059 41.6331 35.4294 42.1538C35.753 42.6745 36.2079 43.0886 36.7424 43.3492C37.277 43.6097 37.8698 43.7062 38.4535 43.6277C39.0372 43.5492 39.5884 43.2988 40.0446 42.905C40.5007 42.5112 40.8435 41.9898 41.034 41.3999C41.2245 40.8101 41.255 40.1755 41.1222 39.5682C40.9893 38.9609 40.6984 38.4052 40.2824 37.9643L40.282 37.9642ZM39.1697 41.4999C38.9497 41.7327 38.6696 41.8912 38.3646 41.9553C38.0597 42.0194 37.7436 41.9863 37.4564 41.8602C37.1692 41.7341 36.9237 41.5205 36.751 41.2466C36.5783 40.9727 36.4862 40.6507 36.4862 40.3213C36.4862 39.9919 36.5783 39.6699 36.751 39.396C36.9237 39.1221 37.1692 38.9085 37.4564 38.7824C37.7436 38.6563 38.0597 38.6232 38.3646 38.6873C38.6696 38.7514 38.9497 38.9099 39.1697 39.1427C39.4643 39.4555 39.6297 39.8794 39.6297 40.3213C39.6297 40.7632 39.4643 41.1871 39.1697 41.4999Z" fill="white" />
      <path d="M15.2527 35.0179L14.1403 33.8394L10.7329 37.4496C10.2261 37.1334 9.6459 36.9742 9.05804 36.9899C8.47018 37.0055 7.89824 37.1955 7.40716 37.5383C6.91608 37.881 6.52552 38.3627 6.27984 38.9288C6.03415 39.4948 5.94317 40.1225 6.01723 40.7406C6.09129 41.3587 6.32742 41.9424 6.69881 42.4255C7.07021 42.9085 7.56199 43.2716 8.11832 43.4734C8.67466 43.6753 9.27326 43.7078 9.84617 43.5674C10.4191 43.4269 10.9433 43.1191 11.3595 42.6789C11.8519 42.1557 12.1667 41.4744 12.2545 40.7415C12.3424 40.0086 12.1984 39.2655 11.8452 38.6285L15.2527 35.0179ZM10.2472 41.4999C10.0272 41.7327 9.74704 41.8912 9.44207 41.9554C9.13709 42.0195 8.82101 41.9864 8.53377 41.8603C8.24654 41.7342 8.00105 41.5206 7.82834 41.2467C7.65562 40.9728 7.56344 40.6507 7.56344 40.3213C7.56344 39.9919 7.65562 39.6698 7.82834 39.3959C8.00105 39.1219 8.24654 38.9084 8.53377 38.7823C8.82101 38.6561 9.13709 38.6231 9.44207 38.6872C9.74704 38.7514 10.0272 38.9099 10.2472 39.1427C10.5417 39.4556 10.7071 39.8794 10.7071 40.3213C10.7071 40.7632 10.5417 41.187 10.2472 41.4999Z" fill="white" />
      <path d="M31.9395 14.9826L33.0518 16.1612L36.4592 12.551C37.0605 12.925 37.7618 13.0774 38.4535 12.9843C39.1451 12.8912 39.7882 12.5579 40.282 12.0364C40.698 11.5954 40.9889 11.0398 41.1218 10.4324C41.2546 9.82511 41.224 9.19049 41.0336 8.60065C40.8431 8.01081 40.5003 7.48939 40.0441 7.09561C39.5879 6.70183 39.0367 6.45147 38.453 6.37297C37.8693 6.29447 37.2765 6.39097 36.7419 6.65151C36.2074 6.91206 35.7525 7.3262 35.429 7.84691C35.1054 8.36762 34.9262 8.97402 34.9116 9.59725C34.897 10.2205 35.0477 10.8355 35.3465 11.3726L31.9395 14.9826ZM36.9449 8.50065C37.1649 8.26756 37.4452 8.10883 37.7504 8.04453C38.0555 7.98022 38.3718 8.01324 38.6593 8.13939C38.9467 8.26554 39.1924 8.47917 39.3652 8.75325C39.5381 9.02734 39.6303 9.34958 39.6303 9.67922C39.6303 10.0089 39.5381 10.3311 39.3652 10.6052C39.1924 10.8793 38.9467 11.0929 38.6593 11.219C38.3718 11.3452 38.0555 11.3782 37.7504 11.3139C37.4452 11.2496 37.1649 11.0909 36.9449 10.8578C36.7983 10.7034 36.6819 10.5197 36.6025 10.3175C36.5231 10.1152 36.4822 9.89829 36.4822 9.67922C36.4822 9.46015 36.5231 9.24324 36.6025 9.04098C36.6819 8.83871 36.7983 8.65508 36.9449 8.50065Z" fill="white" />
      <path d="M32.7041 38.5912L31.7923 37.2329C29.6189 38.8754 27.0486 39.8276 24.3827 39.9779V38.3339H22.8096V39.9779C20.1438 39.8276 17.5735 38.8754 15.4001 37.2329L14.4883 38.5912C16.9278 40.4347 19.8166 41.4953 22.8096 41.6465V43.439C22.0682 43.6418 21.422 44.1242 20.9922 44.7957C20.5625 45.4672 20.3786 46.2817 20.4751 47.0866C20.5716 47.8915 20.9418 48.6314 21.5164 49.1678C22.091 49.7041 22.8304 50 23.5962 50C24.3619 50 25.1014 49.7041 25.676 49.1678C26.2506 48.6314 26.6208 47.8915 26.7173 47.0866C26.8138 46.2817 26.6299 45.4672 26.2001 44.7957C25.7703 44.1242 25.1242 43.6418 24.3827 43.439V41.6465C27.3758 41.4953 30.2646 40.4347 32.7041 38.5912ZM25.1693 46.6674C25.1693 46.9971 25.077 47.3193 24.9042 47.5934C24.7313 47.8675 24.4856 48.0811 24.1982 48.2073C23.9107 48.3334 23.5944 48.3664 23.2893 48.3021C22.9841 48.2378 22.7038 48.0791 22.4838 47.846C22.2638 47.6129 22.114 47.3159 22.0533 46.9926C21.9926 46.6693 22.0238 46.3342 22.1428 46.0296C22.2619 45.7251 22.4635 45.4647 22.7222 45.2816C22.9809 45.0985 23.2851 45.0007 23.5962 45.0007C24.0132 45.0012 24.4131 45.177 24.708 45.4895C25.0029 45.8019 25.1688 46.2255 25.1693 46.6674Z" fill="white" />
      <path d="M14.4883 11.4092L15.4001 12.7675C17.5735 11.125 20.1438 10.1728 22.8096 10.0225V11.6665H24.3827V10.0225C27.0486 10.1728 29.6189 11.125 31.7923 12.7675L32.7041 11.4092C30.2646 9.56574 27.3758 8.5051 24.3827 8.35395V6.56099C25.1242 6.35816 25.7703 5.87578 26.2001 5.20428C26.6299 4.53278 26.8138 3.71826 26.7173 2.91339C26.6208 2.10853 26.2506 1.36858 25.676 0.832239C25.1014 0.295901 24.3619 0 23.5962 0C22.8304 0 22.091 0.295901 21.5164 0.832239C20.9418 1.36858 20.5716 2.10853 20.4751 2.91339C20.3786 3.71826 20.5625 4.53278 20.9922 5.20428C21.422 5.87578 22.0682 6.35816 22.8096 6.56099V8.35395C19.8166 8.5051 16.9278 9.56574 14.4883 11.4092ZM22.0231 3.33299C22.0231 3.00334 22.1154 2.6811 22.2882 2.40701C22.4611 2.13293 22.7067 1.9193 22.9942 1.79315C23.2816 1.667 23.5979 1.63399 23.9031 1.6983C24.2082 1.76261 24.4885 1.92135 24.7085 2.15445C24.9285 2.38754 25.0784 2.68452 25.1391 3.00783C25.1997 3.33114 25.1686 3.66626 25.0495 3.97081C24.9305 4.27536 24.7288 4.53567 24.4701 4.71881C24.2115 4.90195 23.9073 4.9997 23.5962 4.9997C23.1791 4.99917 22.7793 4.82341 22.4844 4.51095C22.1895 4.1985 22.0236 3.77487 22.0231 3.33299Z" fill="white" />
    </svg>
  )
}


