import React from 'react';

function ArrowCarousel() {
	return (
		<svg width="13" height="30" viewBox="0 0 13 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 27.9526L11 14.9948L2 1.69588" stroke="#C00000" strokeWidth="3" strokeLinecap="round" />
		</svg>
	)
}

export default ArrowCarousel;

