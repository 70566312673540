import React from 'react'
import Content from './Content'
import { useStaticQuery, graphql } from "gatsby"

const HeroSection = () => {

  const data = useStaticQuery(graphql`
        query Query {
          allContentfulHomePageHeroText {
            nodes {
              title
              description {
                description
              }
            }
          }
        }
      `)

  return (
    <Content data={data.allContentfulHomePageHeroText.nodes[0]} />
  )
}

export default HeroSection
