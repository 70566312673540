import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import theme from 'styles/theme'
import styled from 'styled-components'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Container from 'components/global/Container'
import Heading from 'components/global/Heading'

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
    align-items: center;
  }
  margin-bottom: 100px;
`
const StyledGatsbyImage = styled(GatsbyImage)`
  margin: 0 40px;
  filter: grayscale(100%);
`

const sliderSettings = {
  slidesToShow: 7,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplayTimeout: 0,
  speed: 500,
  responsive: [
    {
      breakpoint: theme.breakpoints.xxl,
      settings: {
        slidesToShow: 6,
      }
    },
    {
      breakpoint: theme.breakpoints.xl,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: theme.breakpoints.lg,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: theme.breakpoints.sm,
      settings: {
        slidesToShow: 2,
      }
    },
  ]
};

const TheyTrustUS = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulTheyTrustUs{
        nodes {
          name
          logo {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const companies = data.allContentfulTheyTrustUs.nodes
  const logosRender = companies.map(company => {
    const logoImage = getImage(company.logo)
    return (
      <div key={company.name}>
        <StyledGatsbyImage image={logoImage} alt={company.name} />
      </div>
    )
  })

  return (
    <Container>
      <Heading section>Ils nous font confiance</Heading>
      <StyledSlider {...sliderSettings}>
        {logosRender}
      </StyledSlider>
    </Container>
  );
}

export default TheyTrustUS;
