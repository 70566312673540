import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Container from 'components/global/Container'
import Heading from 'components/global/Heading'
import Text from 'components/global/Text'
import Button from 'components/global/Button';
import BgPattern from 'assets/imgs/home/BgPattern1';

const StyledSection = styled.section`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  ${breakpoint('lg')`
    margin-top: 40px;
    flex-direction: row;
  `}
  & > * {
    flex: 50%;
  }
`
const StyledTextContainer = styled.div`   
  margin: 30px 0 90px 0;
  ${breakpoint('lg')`
    margin: 90px 0 0 0;
  `}
  p {
    font-weight: 700;
    font-size: 18px;
  }
  div {
    display: flex;
    margin-top: 40px;
    button {margin-right: 15px}
  }
`
const StyledImgsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${breakpoint('lg')`
    justify-content: flex-end;
    padding-left: 60px;
  `}
  img {
    border-radius: 20px;
  }
`
const StyledImgCol = styled.div`
  display: flex;
  flex-direction: column;
  .gatsby-image-wrapper {
    margin: 0 15px 15px 0;
  }
  &:nth-child(2) {
    margin-top: 45px;
    .gatsby-image-wrapper {
      margin: 0 0 15px 0;
    }
  }
`
const StyledBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoint('sm')`
    flex-direction: row;
  `}
  button:first-child {
    margin-bottom: 15px;
    ${breakpoint('sm')`
      margin-bottom: 0;
    `}
  }
`
const StyledBgPatternContainer = styled.div`
  position: relative;
`
const StyledBgPattern = styled(BgPattern)`
  position: absolute;
  left: 0;
  top: 200px;
  z-index: -1;
`

const Content = ({ data }) => {

  const { title, description } = data

  return (
    <StyledBgPatternContainer>
      <Container>
        <StyledSection>
          <StyledTextContainer>
            <Heading>
              {title}
            </Heading>
            <Text heroDescription >
              {description.description}
            </Text>
            <StyledBtnsContainer>
              <AnchorLink href="#expertisesSection" offset='100'>
                <Button >
                  Nos domaines d'expertises
                </Button>
              </AnchorLink>
              <AnchorLink href="#aboutSection" offset='100'>
                <Button secondary>
                  Notre ADN
                </Button>
              </AnchorLink>
            </StyledBtnsContainer>
          </StyledTextContainer>
          <StyledImgsContainer>
            <StyledImgCol>
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/imgs/home/cao.jpg"
                alt="LM Ingénierie"
                width={270}
                height={220}
              />
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/imgs/home/fiberglass.jpg"
                alt="LM Ingénierie"
                width={270}
                height={410}
              />
            </StyledImgCol>
            <StyledImgCol>
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/imgs/home/light-building.jpg"
                alt="LM Ingénierie"
                width={270}
                height={360}
              />
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/imgs/home/working-map.jpg"
                alt="LM Ingénierie"
                width={270}
                height={300}
              />
            </StyledImgCol>
          </StyledImgsContainer>
        </StyledSection>
      </Container>
      <StyledBgPattern />
    </StyledBgPatternContainer>
  );
}

export default Content;
