import React from 'react';

function Index({ className }) {
  return (
    <svg className={className} width="559" height="921" viewBox="0 0 559 921" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.04">
        <path d="M204.204 567.583H160.09C146.478 567.583 135.443 556.548 135.443 542.936V498.822C135.443 485.209 146.478 474.174 160.09 474.174H204.204C217.817 474.174 228.852 485.209 228.852 498.822V542.936C228.852 556.548 217.817 567.583 204.204 567.583Z" fill="#C91200" />
        <path d="M31.4601 471.545H70.198C86.2276 470.634 101.476 464.319 113.455 453.629L114.908 452.014C125.654 440.07 131.978 424.801 132.824 408.757V370.18C132.824 363.46 135.494 357.014 140.246 352.262C144.999 347.51 151.445 344.839 158.166 344.839H203.521C210.242 344.839 216.688 347.51 221.44 352.262C226.192 357.014 228.862 363.46 228.862 370.18V415.536C228.862 422.257 226.192 428.702 221.44 433.455C216.688 438.207 210.242 440.877 203.521 440.877H164.944C148.9 441.724 133.632 448.048 121.687 458.793L120.073 460.246C109.361 472.212 103.043 487.468 102.157 503.504L102.157 542.242C102.157 548.962 99.4873 555.408 94.735 560.16C89.9819 564.913 83.5367 567.583 76.8157 567.583H31.4601C24.739 567.583 18.2938 564.913 13.5415 560.16C8.78916 555.408 6.11914 548.962 6.11914 542.242L6.11914 496.885C6.11914 490.165 8.78916 483.72 13.5415 478.967C18.2938 474.215 24.739 471.545 31.4601 471.545Z" fill="#364350" />
        <path d="M274.193 712.371H312.93C328.96 711.46 344.209 705.145 356.188 694.454L357.641 692.84C368.386 680.896 374.71 665.627 375.556 649.583V611.006C375.556 604.286 378.226 597.84 382.979 593.088C387.731 588.335 394.177 585.665 400.898 585.665H446.254C452.974 585.665 459.42 588.335 464.172 593.088C468.925 597.84 471.595 604.286 471.595 611.006V656.362C471.595 663.083 468.925 669.528 464.172 674.28C459.42 679.033 452.974 681.703 446.254 681.703H407.677C391.633 682.55 376.364 688.874 364.419 699.619L362.806 701.072C352.094 713.038 345.775 728.293 344.889 744.33V783.067C344.889 789.788 342.22 796.234 337.467 800.986C332.714 805.738 326.269 808.408 319.548 808.408H274.193C267.471 808.408 261.026 805.738 256.274 800.986C251.522 796.234 248.852 789.788 248.852 783.067V737.711C248.852 730.991 251.522 724.546 256.274 719.793C261.026 715.041 267.471 712.371 274.193 712.371Z" fill="#364350" />
        <path d="M403.263 465.544H442.001C458.03 464.633 473.279 458.318 485.258 447.627L486.711 446.013C497.457 434.069 503.78 418.8 504.627 402.755V364.179C504.627 357.459 507.297 351.013 512.049 346.261C516.801 341.508 523.247 338.838 529.968 338.838H575.324C582.044 338.838 588.49 341.508 593.243 346.261C597.995 351.013 600.665 357.459 600.665 364.179V409.535C600.665 416.256 597.995 422.701 593.243 427.453C588.49 432.206 582.044 434.876 575.324 434.876H536.747C520.703 435.723 505.434 442.047 493.49 452.792L491.876 454.245C481.164 466.211 474.845 481.466 473.959 497.502V536.24C473.959 542.961 471.29 549.407 466.538 554.159C461.785 558.911 455.339 561.581 448.618 561.581H403.263C396.542 561.581 390.097 558.911 385.344 554.159C380.592 549.407 377.922 542.961 377.922 536.24V490.884C377.922 484.164 380.592 477.719 385.344 472.966C390.097 468.214 396.542 465.544 403.263 465.544Z" fill="#C91200" />
        <path d="M582.569 306.743H544.196C528.222 307.78 513.087 314.407 501.345 325.506L497.028 329.91C486.384 341.985 480.119 357.419 479.28 373.636V412.793C479.238 419.573 476.58 426.064 471.882 430.858C467.182 435.653 460.822 438.366 454.177 438.408H415.804C399.961 439.088 384.831 445.31 372.953 456.028L368.637 460.434C357.992 472.507 351.727 487.941 350.888 504.159V543.152C350.888 549.946 348.243 556.461 343.536 561.265C338.828 566.069 332.444 568.767 325.786 568.767H280.856C274.199 568.767 267.814 566.069 263.107 561.265C258.399 556.461 255.754 549.946 255.754 543.152V497.306C255.754 490.513 258.399 483.997 263.107 479.194C267.814 474.39 274.199 471.691 280.856 471.691H319.07C335.206 470.938 350.583 464.478 362.561 453.418L366.878 449.013C377.489 436.918 383.748 421.497 384.625 405.288V366.131C384.667 359.351 387.325 352.86 392.024 348.066C396.722 343.271 403.083 340.559 409.728 340.516H448.101C463.819 339.512 478.746 333.132 490.473 322.406L494.79 318.001C505.401 305.906 511.66 290.484 512.537 274.276V235.119C512.579 228.338 515.238 221.848 519.936 217.054C524.634 212.259 530.995 209.547 537.64 209.504H582.729C589.374 209.547 595.735 212.259 600.433 217.054C605.132 221.848 607.79 228.338 607.832 235.119V281.128C607.789 287.936 605.109 294.452 600.376 299.251C595.643 304.05 589.241 306.744 582.569 306.743Z" fill="#364350" />
        <path d="M582.885 190.889L535.377 190.889C520.718 190.889 508.834 179.854 508.834 166.242V122.128C508.834 108.515 520.718 97.481 535.377 97.481L582.885 97.481C597.544 97.481 609.428 108.515 609.428 122.128V166.242C609.428 179.854 597.544 190.889 582.885 190.889Z" fill="#364350" />
        <path d="M453.156 312.728H414.579C398.535 313.575 383.266 319.899 371.322 330.644L369.708 332.097C358.928 344.147 352.605 359.529 351.791 375.677V414.415C351.712 420.942 349.116 427.187 344.546 431.848C339.975 436.508 333.782 439.226 327.257 439.433H281.095C274.374 439.433 267.929 436.763 263.176 432.011C258.424 427.259 255.754 420.813 255.754 414.092V368.737C255.754 362.016 258.424 355.57 263.176 350.817C267.929 346.065 274.374 343.396 281.095 343.396H319.833C335.862 342.485 351.111 336.17 363.09 325.479L364.543 323.866C375.289 311.921 381.612 296.652 382.459 280.608V242.032C382.459 238.69 383.12 235.382 384.403 232.297C385.687 229.212 387.568 226.411 389.939 224.056C392.308 221.7 395.121 219.837 398.215 218.573C401.308 217.31 404.62 216.669 407.961 216.691H453.156C459.876 216.691 466.322 219.36 471.075 224.113C475.827 228.865 478.497 235.311 478.497 242.032V287.387C478.497 294.108 475.827 300.554 471.075 305.306C466.322 310.058 459.876 312.728 453.156 312.728Z" fill="#364350" />
        <path d="M329.803 678.737H282.295C267.636 678.737 255.752 667.702 255.752 654.09V609.976C255.752 596.363 267.636 585.329 282.295 585.329H329.803C344.462 585.329 356.346 596.363 356.346 609.976V654.09C356.346 667.702 344.462 678.737 329.803 678.737Z" fill="#364350" />
        <path d="M208.051 326.408H160.543C145.884 326.408 134 315.373 134 301.761V257.647C134 244.034 145.884 233 160.543 233L208.051 233C222.71 233 234.594 244.034 234.594 257.647V301.761C234.594 315.373 222.71 326.408 208.051 326.408Z" fill="#C91200" />
        <path d="M208.051 215.408L160.543 215.408C145.884 215.408 134 204.373 134 190.761V146.647C134 133.034 145.884 122 160.543 122L208.051 122C222.71 122 234.594 133.034 234.594 146.647V190.761C234.594 204.373 222.71 215.408 208.051 215.408Z" fill="#364350" />
        <path d="M459.049 93.4084L411.541 93.4084C396.882 93.4084 384.998 82.3733 384.998 68.7609V24.6469C384.998 11.0344 396.882 2.08264e-05 411.541 2.08264e-05L459.049 2.08264e-05C473.708 2.08264e-05 485.592 11.0344 485.592 24.6469V68.7609C485.592 82.3733 473.708 93.4084 459.049 93.4084Z" fill="#364350" />
        <path d="M90.0506 325.408H42.5431C27.8837 325.408 16 314.373 16 300.761L16 256.647C16 243.034 27.8837 232 42.5431 232L90.0506 232C104.71 232 116.594 243.034 116.594 256.647L116.594 300.761C116.594 314.373 104.71 325.408 90.0506 325.408Z" fill="#364350" />
        <path d="M75.0486 678.408H27.5411C12.8817 678.408 0.998047 667.373 0.998047 653.761L0.998047 609.647C0.998047 596.034 12.8817 585 27.5411 585H75.0486C89.708 585 101.592 596.034 101.592 609.647L101.592 653.761C101.592 667.373 89.708 678.408 75.0486 678.408Z" fill="#364350" />
        <path d="M574.049 920.408H526.541C511.882 920.408 499.998 909.373 499.998 895.761V851.647C499.998 838.034 511.882 827 526.541 827H574.049C588.708 827 600.592 838.034 600.592 851.647V895.761C600.592 909.373 588.708 920.408 574.049 920.408Z" fill="#364350" />
      </g>
    </svg>
  )
}
export default Index;
