import * as React from "react"
import Layout from 'components/global/Layout'

import Seo from 'components/global/Seo'
import HeroSection from 'components/pages/home/HeroSection';
import ExpertiseSection from 'components/pages/home/ExpertiseSection';
import AboutSection from 'components/pages/home/AboutSection';
import NewsSection from 'components/pages/home/NewsSection';
import TrustSection from 'components/pages/home/TrustSection';

const HomePage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <HeroSection />
        <div id="expertisesSection">
          <ExpertiseSection />
        </div>
        <div id="aboutSection">
          <AboutSection />
        </div>
        <NewsSection />
        <TrustSection />
      </Layout >
    </>
  )
}


export default HomePage

