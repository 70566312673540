import React from 'react';
import theme from 'styles/theme'
import breakpoint from 'styled-components-breakpoint';
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'gatsby'

import expertisesData from 'data/expertises'
import Container from 'components/global/Container'
import Heading from 'components/global/Heading'
import Button from 'components/global/Button';
import ArrowCarousel from 'assets/icons/ArrowCarousel';

const NextArrow = ({ className, onClick }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-hidden="true"
      aria-label="suivant"
    >
      <ArrowCarousel />
    </button>
  )
}

const PrevArrow = ({ className, onClick }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-hidden="true"
      aria-label="prédédent"
    >
      <ArrowCarousel />
    </button>
  )
}

const sliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: theme.breakpoints.xl,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        slidesToShow: 1,
        centerMode: true,
      }
    },
    {
      breakpoint: theme.breakpoints.sm,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      }
    },
  ]
};

const Index = () => {

  const expertiseSections = expertisesData.map(expertise => {
    return (
      <div key={expertise.name}>
        {expertise.icon}
        <StyledHeadingContainer>
          <Heading two>{expertise.name}</Heading>
        </StyledHeadingContainer>
        <StyledParagraph>{expertise.homePageDescription}</StyledParagraph>
        <Link to="/expertises" state={{ expertiseLinked: expertise.name }}>
          <Button secondary>En savoir plus</Button>
        </Link>
      </div>
    )
  })

  return (
    <Container>
      <Heading section>Nos domaines d’expertise</Heading>
      <StyledSlider {...sliderSettings}>
        {expertiseSections}
      </StyledSlider>
    </Container>
  );
}

const StyledSlider = styled(Slider)`
   ${breakpoint('xl')`
      padding: 0 30px;
  `}
  .slick-arrow {
    width: unset;
    height: unset;
    &::before, &::after {
      display: none;
    }
    &.slick-prev {
      top: 46%;
      left: 0;
      transform: rotate(180deg);
      ${breakpoint('sm')`
        left: -25px;
      `}
    }
    &.slick-next {
      right: 0;
      ${breakpoint('sm')`
        right: -25px;
      `}
    }
  }
  .slick-slide {
    padding: 0 50px;
    svg {
      max-width: 65px;    
      max-height: 65px;
    }
  }
`
const StyledParagraph = styled.p`
  margin-bottom: 25px;
  ${breakpoint('md')`
    max-width: 270px;
    height: 174px;
  `}
  ${breakpoint('xl')`
    height: 174px;
  `}
  ${breakpoint('xxl')`
    height: 144px;
  `}
`
const StyledHeadingContainer = styled.div`
  margin-top: 30px;
  height: 54px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export default Index;
