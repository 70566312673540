import React from 'react'

export default function certication() {
  return (
    <svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.125 33.9375C3.125 34.3953 3.475 34.7653 3.90625 34.7653C4.33672 34.7653 4.6875 35.1361 4.6875 35.593C4.6875 36.0508 5.0375 36.4208 5.46875 36.4208H9.375V34.7653H6.11641C5.88047 34.0601 5.35234 33.5013 4.6875 33.2513V29.7988H3.125V33.9375Z" fill="white" />
      <path d="M45.3125 35.593C45.3125 35.1361 45.6625 34.7653 46.0938 34.7653C46.5258 34.7653 46.875 34.3953 46.875 33.9376V5.79427C46.875 5.33652 46.5258 4.96652 46.0938 4.96652C45.6625 4.96652 45.3125 4.59569 45.3125 4.13878C45.3125 3.68104 44.9633 3.31104 44.5312 3.31104H5.46875C5.0375 3.31104 4.6875 3.68104 4.6875 4.13878C4.6875 4.59569 4.33672 4.96652 3.90625 4.96652C3.475 4.96652 3.125 5.33652 3.125 5.79427V28.1434H4.6875V6.47964C5.35313 6.22966 5.88047 5.67176 6.11641 4.96652H43.8836C44.1195 5.67176 44.6477 6.23049 45.3125 6.48047V33.2514C44.6469 33.5013 44.1195 34.0609 43.8836 34.7653H26.5625V36.4208H44.5312C44.9633 36.4208 45.3125 36.0508 45.3125 35.593Z" fill="white" />
      <path d="M48.4375 0H1.5625C0.700781 0 0 0.742486 0 1.65549V38.0762C0 38.9892 0.700781 39.7317 1.5625 39.7317H10.1562V38.0762H1.5625V1.65549H48.4375V38.0762H25V39.7317H48.4375C49.2992 39.7317 50 38.9892 50 38.0762V1.65549C50 0.742486 49.2992 0 48.4375 0Z" fill="white" />
      <path d="M25.6287 30.9933L24.0537 28.5018L23.4607 25.5898C23.3959 25.2686 23.1576 25.0195 22.8537 24.9532L20.0256 24.339L17.6061 22.7092C17.3506 22.5362 17.0232 22.5362 16.7678 22.7092L14.3482 24.339L11.5201 24.9532C11.2162 25.0186 10.9787 25.2686 10.9131 25.5898L10.3201 28.5018L8.74512 30.9933C8.56934 31.2722 8.56934 31.6356 8.74512 31.9146L10.3201 34.4061L10.9131 37.3181C10.9779 37.6392 11.2162 37.8884 11.5201 37.9546L12.4998 38.1665V45.526C12.4998 45.8256 12.6529 46.1021 12.8998 46.2486C13.1467 46.3951 13.4498 46.3877 13.6904 46.2312L17.1873 43.9508L20.6842 46.2312C20.8092 46.3123 20.9514 46.3537 21.0936 46.3537C21.2248 46.3537 21.3561 46.319 21.4748 46.2486C21.7217 46.1021 21.8748 45.8256 21.8748 45.526V38.1665L22.8537 37.9538C23.1576 37.8884 23.3951 37.6384 23.4607 37.3172L24.0537 34.4052L25.6287 31.9137C25.8045 31.6364 25.8045 31.2722 25.6287 30.9933ZM20.3123 44.0443L17.5967 42.2738C17.3459 42.1107 17.0287 42.1107 16.7779 42.2738L14.0623 44.0443V38.5067L14.349 38.5688L16.7686 40.1986C16.8967 40.2847 17.042 40.3277 17.1881 40.3277C17.3342 40.3277 17.4795 40.2847 17.6076 40.1986L20.0271 38.5688L20.3123 38.5067V44.0443ZM22.6725 33.6164C22.6178 33.7033 22.5787 33.801 22.5584 33.9028L22.0412 36.4407L19.5709 36.977C19.4779 36.9969 19.3889 37.035 19.3084 37.0896L17.1873 38.5191L15.0662 37.0896C14.9857 37.0358 14.8967 36.9977 14.8037 36.977L12.3334 36.4407L11.8162 33.9028C11.7951 33.801 11.7568 33.7041 11.7021 33.6164L10.335 31.4543L11.7021 29.2923C11.7568 29.2054 11.7959 29.1077 11.8162 29.0059L12.3334 26.468L14.8037 25.9316C14.8967 25.9118 14.9857 25.8737 15.0662 25.8191L17.1873 24.3895L19.3084 25.8191C19.3889 25.8729 19.4779 25.9109 19.5709 25.9316L22.0412 26.468L22.5584 29.0059C22.5795 29.1077 22.6178 29.2045 22.6725 29.2923L24.0396 31.4543L22.6725 33.6164Z" fill="white" />
      <path d="M17.1875 26.4878C14.6023 26.4878 12.5 28.7153 12.5 31.4543C12.5 34.1933 14.6023 36.4207 17.1875 36.4207C19.7727 36.4207 21.875 34.1933 21.875 31.4543C21.875 28.7153 19.7727 26.4878 17.1875 26.4878ZM17.1875 34.7652C15.4641 34.7652 14.0625 33.2803 14.0625 31.4543C14.0625 29.6283 15.4641 28.1433 17.1875 28.1433C18.9109 28.1433 20.3125 29.6283 20.3125 31.4543C20.3125 33.2803 18.9109 34.7652 17.1875 34.7652Z" fill="white" />
      <path d="M20.3125 13.2439H29.6875C29.9398 13.2439 30.1773 13.1148 30.3234 12.8962L31.1047 11.7333C31.1992 11.5925 31.25 11.4253 31.25 11.2532V8.69131C31.25 8.52824 31.2039 8.36766 31.1188 8.23191L30.3375 6.99029C30.1922 6.76018 29.9484 6.62195 29.6875 6.62195H20.3125C20.0438 6.62195 19.7937 6.76846 19.6508 7.01016L18.8695 8.32958C18.7914 8.46119 18.75 8.61267 18.75 8.76912V11.0976C18.75 11.2532 18.7914 11.4055 18.8695 11.5371L19.6508 12.8557C19.7937 13.0974 20.0438 13.2439 20.3125 13.2439ZM20.3125 9.00751L20.7445 8.27744H29.2695L29.6875 8.94211V10.9891L29.2844 11.5884H20.7445L20.3125 10.8592V9.00751Z" fill="white" />
      <path d="M35.9375 9.10522H32.8125V10.7607H35.9375V9.10522Z" fill="white" />
      <path d="M17.1875 9.10522H14.0625V10.7607H17.1875V9.10522Z" fill="white" />
      <path d="M37.5 16.5549H12.5V18.2104H37.5V16.5549Z" fill="white" />
      <path d="M37.5 21.5214H21.875V23.1769H37.5V21.5214Z" fill="white" />
      <path d="M37.5 26.4878H26.5625V28.1433H37.5V26.4878Z" fill="white" />
      <path d="M42.1875 13.2439H40.625V14.8994H42.1875V13.2439Z" fill="white" />
      <path d="M9.375 13.2439H7.8125V14.8994H9.375V13.2439Z" fill="white" />
      <path d="M42.1875 29.7988H40.625V31.4543H42.1875V29.7988Z" fill="white" />
    </svg>

  )
}


