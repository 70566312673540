import React from 'react';

function Pluridisciplinaire() {
	return (
		<svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4.54443 9.01901L5.7703 8.15339L4.56948 6.24419L5.7703 4.33576L4.54443 3.46936L3.07103 5.81099C2.90601 6.07325 2.90601 6.41434 3.07103 6.67661L4.54443 9.01901Z" fill="white" />
			<path d="M11.6634 9.01909L13.1368 6.67746C13.3018 6.4152 13.3018 6.0741 13.1368 5.81184L11.6634 3.47021L10.4375 4.33584L11.6383 6.24426L10.4375 8.15268L11.6634 9.01909Z" fill="white" />
			<path d="M8.14225 3.65581L6.66797 8.33899L8.06563 8.83292L9.53991 4.14975L8.14225 3.65581Z" fill="white" />
			<path d="M12.5239 23.4163C12.7191 23.4163 12.907 23.3343 13.0447 23.1876L15.9915 20.0654C16.13 19.9194 16.2074 19.7212 16.2074 19.5135V0.780542C16.2074 0.348902 15.8773 0 15.4707 0H0.736699C0.330041 0 0 0.348902 0 0.780542V22.6357C0 23.0674 0.330041 23.4163 0.736699 23.4163H12.5239ZM12.5239 21.532V19.5135H14.429L12.5239 21.532ZM1.4734 1.56108H14.734V17.9525H11.7872C11.3805 17.9525 11.0505 18.3014 11.0505 18.733V21.8552H1.4734V1.56108Z" fill="white" />
			<path d="M10.3143 10.9276H2.94727V12.4887H10.3143V10.9276Z" fill="white" />
			<path d="M13.2605 10.9276H11.7871V12.4887H13.2605V10.9276Z" fill="white" />
			<path d="M10.3143 14.0497H2.94727V15.6108H10.3143V14.0497Z" fill="white" />
			<path d="M13.2605 14.0497H11.7871V15.6108H13.2605V14.0497Z" fill="white" />
			<path d="M6.63076 17.1719H2.94727V18.733H6.63076V17.1719Z" fill="white" />
			<path d="M9.57691 17.1719H8.10352V18.733H9.57691V17.1719Z" fill="white" />
			<path d="M33.1558 34.3712L28.5139 32.2169L26.8777 29.9049C27.9798 29.2297 28.7312 27.9816 28.7312 26.5384V19.5135C28.7312 17.3616 27.0788 15.6108 25.0477 15.6108H22.1009C20.0698 15.6108 18.4174 17.3616 18.4174 19.5135V26.5384C18.4174 27.9816 19.1688 29.2297 20.2709 29.9049L18.6436 32.2044L14.1003 34.1331C12.6947 34.7302 11.7871 36.1516 11.7871 37.7556V49.1741C11.7871 49.605 12.1164 49.9539 12.5223 49.9547L34.6233 49.9999C34.6233 49.9999 34.624 49.9999 34.6248 49.9999C34.82 49.9999 35.0071 49.918 35.1456 49.772C35.2841 49.6253 35.3615 49.4262 35.3615 49.2194V37.946C35.3615 36.3959 34.4959 34.9925 33.1558 34.3712ZM19.8908 19.5135C19.8908 18.2225 20.8824 17.1719 22.1009 17.1719H25.0477C26.2662 17.1719 27.2578 18.2225 27.2578 19.5135V21.8552H25.0477C23.8292 21.8552 22.8376 20.8046 22.8376 19.5135V18.733H21.3642V19.5135C21.3642 21.6655 23.0166 23.4163 25.0477 23.4163H27.2578V26.5384C27.2578 27.8294 26.2662 28.88 25.0477 28.88H22.1009C20.8824 28.88 19.8908 27.8294 19.8908 26.5384V19.5135ZM21.7325 30.4411H25.416L26.9381 32.5907L23.5743 34.9667L20.2105 32.5907L21.7325 30.4411ZM33.8881 48.4373L30.9413 48.4311V42.1493H29.4679V48.4279L17.6807 48.4037V42.1493H16.2073V48.4014L13.2605 48.3952V37.7556C13.2605 36.794 13.8049 35.94 14.6484 35.5818L19.0686 33.7054L23.1662 36.5996C23.4137 36.7752 23.7356 36.7752 23.9832 36.5996L28.0682 33.7147L32.565 35.8019C33.3687 36.1742 33.8881 37.0156 33.8881 37.946V48.4373Z" fill="white" />
			<path d="M24.3113 41.3687H22.8379V46.0519H24.3113V41.3687Z" fill="white" />
			<path d="M24.3113 38.2466H22.8379V39.8077H24.3113V38.2466Z" fill="white" />
			<path d="M36.8355 15.6108H33.8887V17.1719H36.8355V15.6108Z" fill="white" />
			<path d="M46.4121 0H31.6781C31.2714 0 30.9414 0.348902 30.9414 0.780542V22.6357C30.9414 23.0674 31.2714 23.4163 31.6781 23.4163H46.4121C46.8187 23.4163 47.1488 23.0674 47.1488 22.6357V0.780542C47.1488 0.348902 46.8187 0 46.4121 0ZM32.4148 1.56108H45.6754V3.12217H32.4148V1.56108ZM32.4148 21.8552V4.68325H45.6754V21.8552H32.4148Z" fill="white" />
			<path d="M43.4658 6.24426H34.6254C34.2187 6.24426 33.8887 6.59316 33.8887 7.0248V13.2691C33.8887 13.7008 34.2187 14.0497 34.6254 14.0497H43.4658C43.8724 14.0497 44.2025 13.7008 44.2025 13.2691V7.0248C44.2025 6.59316 43.8724 6.24426 43.4658 6.24426ZM42.7291 12.4886H35.3621V7.80535H42.7291V12.4886Z" fill="white" />
			<path d="M41.2542 9.36658H36.834V10.9277H41.2542V9.36658Z" fill="white" />
			<path d="M44.2022 15.6108H38.3086V17.1719H44.2022V15.6108Z" fill="white" />
			<path d="M41.2557 18.733H33.8887V20.2941H41.2557V18.733Z" fill="white" />
			<path d="M44.2019 18.733H42.7285V20.2941H44.2019V18.733Z" fill="white" />
			<path d="M40.5203 32.7828H39.0469V34.3438H41.257C41.6636 34.3438 41.9937 33.9949 41.9937 33.5633V31.2217H40.5203V32.7828Z" fill="white" />
			<path d="M37.5711 32.7827H36.0977V34.3438H37.5711V32.7827Z" fill="white" />
			<path d="M41.9929 28.0995H40.5195V29.6606H41.9929V28.0995Z" fill="white" />
			<path d="M41.9929 24.9773H40.5195V26.5384H41.9929V24.9773Z" fill="white" />
			<path d="M7.36598 31.2217H5.89258V33.5633C5.89258 33.9949 6.22262 34.3438 6.62928 34.3438H8.83938V32.7828H7.36598V31.2217Z" fill="white" />
			<path d="M11.7879 32.7827H10.3145V34.3438H11.7879V32.7827Z" fill="white" />
			<path d="M7.36598 28.0995H5.89258V29.6606H7.36598V28.0995Z" fill="white" />
			<path d="M7.36598 24.9773H5.89258V26.5384H7.36598V24.9773Z" fill="white" />
			<path d="M18.4186 6.24441H24.3122V4.68332H19.7955L20.5049 3.55544L19.279 2.68982L17.8056 5.03144C17.6546 5.27107 17.6406 5.5786 17.7688 5.83228C17.897 6.08596 18.1467 6.24441 18.4186 6.24441Z" fill="white" />
			<path d="M27.2586 4.68311H25.7852V6.24419H27.2586V4.68311Z" fill="white" />
			<path d="M22.8379 10.9277H27.3546L26.6452 12.0555L27.871 12.9212L29.3444 10.5795C29.4954 10.3399 29.5094 10.0324 29.3813 9.7787C29.2531 9.52503 29.0033 9.36658 28.7315 9.36658H22.8379V10.9277Z" fill="white" />
			<path d="M21.364 9.36658H19.8906V10.9277H21.364V9.36658Z" fill="white" />
		</svg>
	)
}

export default Pluridisciplinaire
